<template>
    <div class="index">
        <div class="image">
            <van-swipe :autoplay="3000" indicator-color="white">
                <van-swipe-item v-for="(image, index) in images" :key="index">
                    <van-image
                            width="100%"
                            height="100%"
                            fit="contain"
                            :src="require('@/assets/'+image)"
                    />
                </van-swipe-item>
            </van-swipe>
        </div>
        <div class="menu">
            <menu-list :data="menu"></menu-list>
        </div>
        <div class="menu">
            <menu-list :data="menu2"></menu-list>
        </div>
        <div class="menu">
          <menu-list :data="menu3"></menu-list>
        </div>
      <div class="menu">
        <menu-list :data="menu4"></menu-list>
      </div>
      <div class="add"
           @mouseenter="changeActive($event)"
          >
        <van-image
            @click="clikAI($event)"
            class="manage"
            :src="require('@/assets/AIzhuli.png')"
        />
      </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import {Lazyload, Toast} from 'vant';

    Vue.use(Lazyload);

    import MenuList from "@/components/MenuList";

    export default {
        name: "Index",
        components: {
            MenuList,
        },
        data() {
            return {
                images: [
                    '1.png',
                    '1.png',
                ],
                menu: {
                    title: '客户管理',
                    menus: [
                        {
                          name: '我的客户',
                          image: 'icon3/wdkh.png',
                          to: 'MyCustomer',
                          use: true
                        },
                        {
                            // name: '客户线索池',
                            name: '客户信息',
                            image: 'icon3/kehu.png',
                            to: 'CustomerList',
                            use: true
                        },
                        {
                            name: '通讯录',
                            image: 'icon3/tongxunlu.png',
                            to: 'MailList',
                            use: true
                        },
                        {
                          name: '船舶信息',
                          image: 'icon3/chuanbo.png',
                          to: 'BulkCrmsShipInfoList/null',
                          use: true
                        },
                        {
                          name: '客户导航',
                          image: 'icon3/dingwei.png',
                          to: 'CustomerArea',
                          use: true
                        },

                    ]
                },
                menu2: {
                    title: '项目管理',
                    menus: [
                        {
                            name: '走访计划',
                            image: 'icon3/buqia.png',
                            to: 'BulkCrmsTravelPlanList',
                            use: true
                        },
                        {
                            name: '走访复盘',
                            image: 'icon3/shijia.png',
                            to: 'BulkCrmsVisitReplayList',
                            use: true
                        },
                        {
                            name: '推进记录',
                            image: 'icon3/kaoqintiaozheng.png',
                            to: 'ProjectProgressRecord',
                            use: true
                        },
                        {
                            name: '临时拜访',
                            image: 'icon3/tongshiquanzi.png',
                            to: 'BulkCrmsVisitTemporaryExistList',
                            use: true
                        }
                    ]
                },
              menu3: {
                title: '报表管理',
                menus: [
                  {
                    name: '客户关系',
                    image: 'icon3/gongzuobaogao.png',
                    to: 'CustomerRelationPanel',
                    use: true
                  },
                  {
                    name: '遗留问题',
                    image: 'icon3/wenti.png',
                    to: 'RemainProblerms',
                    use: true
                  },
                  {
                    name: '待批复看板',
                    image: 'icon3/pifu.png',
                    to: 'LeaderOfficialPanel',
                    count: '',
                    use: true
                  },
                  {
                    name: '关注看板',
                    image: 'icon3/guanzhu.png',
                    to: 'LeaderConcernPanel',
                    count: '',
                    use: true
                  },
                  {
                    name: '发运量统计',
                    image: 'icon3/fkf.png',
                    to: 'CustomerPayer',
                    count: '',
                    use: true
                  },
                  {
                    name: '待处理推送',
                    image: 'icon3/dcl.png',
                    to: 'BulkCrmsTravelTodoList',
                    count: '',
                    use: true
                  },
                ]
              },
              menu4: {
                title: '系统管理',
                menus: [
                  {
                    name: '频次监控',
                    image: 'icon3/chucha.png',
                    to: 'TravelFrequencyPanel',
                    use: true
                  },
                  {
                    name: '监控中心',
                    image: 'icon3/tongzhi.png',
                    to: 'EarlyWarningPlatform',
                    use: true
                  },
                  {
                    name: '客户运维',
                    image: 'icon3/khyw.png',
                    to: 'CustomerManage/0',
                    use: this.$store.getters.userId == '19060' || this.$store.getters.userId == '16310' || this.$store.getters.userId == '18280'
                  },
                  {
                    name: '待开发',
                    image: 'icon3/daikaifa.png',
                    to: 'NotFound',
                    use: true
                  },
                  // {
                  //   name: '预警平台',
                  //   image: 'icon3/tongzhi.png',
                  //   to: 'WarningPanelIndex',
                  //   use: this.$store.getters.userId == '19060' || this.$store.getters.userId == '16310' || this.$store.getters.userId == '18280' || this.$store.getters.userId == '04002'
                  // },
                  // {
                  //   name: '操作手册',
                  //   image: 'chengbenhesuan.png',
                  //   to: 'FileViewer',
                  //   use: true
                  // },
                ]
              },
              // menu5: {
              //   title: '招标平台',
              //   menus: [
              //     {
              //       name: '招标看板',
              //       image: 'icon3/bid.png',
              //       to: 'BidPanel',
              //       use: true
              //     },
              //     {
              //       name: '招标权限',
              //       image: 'icon3/quanxian.png',
              //       to: 'BidPanelAuthority',
              //       use: this.$store.getters.userId == '19060' || this.$store.getters.userId == '18280'
              //     },
              //   ]
              // }
            };
        },
        // mounted() {
        //     this.init()
        // },
        beforeRouteEnter(to, from, next) {
          // 不！能！获取组件实例 `this`
          // 因为当守卫执行前，组件实例还没被创建
          next(vm => {
            // 通过 `vm` 访问组件实例
            setTimeout(() => {
              vm.init()
            }, 50);
          })
        },
        methods: {
            changeActive(e) {
              e.currentTarget.className = 'add1';
              console.log("鼠标移入了");
            },
            clikAI(e) {
              console.log("鼠标移出了");
            },
            init(){
              this.$ajax.post('/api/ajax/cusPush/getPushCountInfo.json',
                  {
                    pushPerson: this.$store.getters.userId
                  }).then(res => {
                if (res.status == 200) {
                  console.log(res.data)
                  setTimeout(() => {
                    this.menu3.menus[2].count = res.data[0].count1
                    this.menu3.menus[3].count = res.data[0].count
                  }, 200);
                }
              }).catch(err => {
              })
            },
          }
    }
</script>

<style scoped>
    .index .image {
        background: white;
        box-shadow: 2px 2px 3px #9a9595;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    .index .menu {
        margin-top: 5px;
        background: white;
    }

    .add {
      position: fixed;
      top: 55%;
      left: 95%;
      width: 45px;
      height: 45px;
      border-radius: 50%;
    }

    .add1 {
      position: fixed;
      top: 55%;
      left: 85%;
      width: 45px;
      height: 45px;
      border-radius: 50%;
    }

    .add:hover {
      right: 15%;
    }

</style>
