<template>
    <div class="menu-list">
        <van-row class="title" style="padding: 5px">
            <span>{{data.title}}</span>
        </van-row>
        <van-row style="padding: 20px">
            <van-col span="6" v-for="(item,index) in data.menus" v-bind:key="index" @click="to(item.to)"  v-if="item.use">
                <menu-button :data="item"></menu-button>
            </van-col>
        </van-row>
    </div>
</template>

<script>
    import MenuButton from "@/components/MenuButton";

    export default {
        name: 'MenuList',
        components: {
            MenuButton
        },
        props: {
            data: {type: Object}
        },
        methods: {
            to(to) {
                this.$router.push(to)
            }
        }
    }
</script>

<style scoped>
    .menu-list .title {
        font-size: 14px;
        text-align: left;
        padding-left: 15px;
        padding-bottom: 5px;
        padding-top: 5px;
        font-weight: 800;
        color: black;
    }
</style>
