<template>
    <div class="menu-button">
        <van-row v-if="data.name=='待批复看板' || data.name=='关注看板'">
          <van-icon size="21px" :name="require('@/assets/'+data.image)" :badge="data.count>99?'99+':data.count" />
        </van-row>
        <van-row v-else :style="data.name=='客户发运量统计'?'margin-top: 15px':'', data.name=='待处理推送'?'margin-top: 0px':''">
          <van-icon size="21px" :name="require('@/assets/'+data.image)"/>
        </van-row>
        <van-row>
            <div class="button-name">
                {{data.name}}
            </div>
        </van-row>
    </div>
</template>

<script>
    export default {
        name: "MenuButton",
        props: {
            data: {type: Object}
        },
    }
</script>

<style scoped>
    .menu-button .button-name {
        font-size: 14px;
        padding-bottom: 10px;
    }

</style>
